import { getTaskTemplate } from "@/features/templates/api"
import { fetcher } from "@/utils/api"
import { getConfig } from "@/utils/api/tables"
import { groupBy } from "lodash"
import { ITask } from "pages/projects"
import { IIntroTemplateBody } from "../types"

export const getIntroTemplates = async () => {
  return await fetcher("/intro_templates")
}

export const getIntroTemplate = async (uid: string) => {
  return await fetcher(`/intro_templates/${uid}`)
}

export const getTaskIntroTemplate = async (template: string) => {
  return await fetcher(`/intro_templates/templates/${template}`)
}

export const getIntroTemplateByTemplateUid = async (template: string) => {
  return await fetcher(`/intro_templates/templates/${template}`)
}

export const createIntroTemplate = async (template: IIntroTemplateBody) => {
  return await fetcher("/intro_templates", {
    method: "POST",
    body: JSON.stringify(template),
  })
}

export const updateIntroTemplate = async (
  uid: string,
  template: IIntroTemplateBody,
) => {
  return await fetcher(`/intro_templates/${uid}`, {
    method: "PUT",
    body: JSON.stringify(template),
  })
}

export const deleteIntroTemplate = async (uid: string) => {
  return await fetcher(`/intro_templates/${uid}`, {
    method: "DELETE",
  })
}

export const getTemplateFromTasks = async (
  templateUid: string,
  fetchedTasks: ITask[],
) => {
  const tasksGroupedOnTemplate = groupBy(fetchedTasks, "template.uid")
  const taskTemplate = await getTaskTemplate(templateUid)
  const tasks = tasksGroupedOnTemplate[templateUid]
  const configs = await getConfig(templateUid)
  if (taskTemplate.is_minibid) {
    const introTemplate = await getIntroTemplateByTemplateUid(templateUid)
    return {
      ...taskTemplate,
      configs,
      ...configs.columns,
      tasks,
      deadlineField: introTemplate.deadline,
    }
  }
  return { ...taskTemplate, configs, ...configs.columns, tasks }
}

export const getTemplatesFromTasks = async (fetchedTasks: ITask[]) => {
  const tasksGroupedOnTemplate = groupBy(fetchedTasks, "template.uid")
  const taskTemplates = Promise.all(
    Object.keys(tasksGroupedOnTemplate).map(async (templateUid) => {
      const taskTemplate = await getTaskTemplate(templateUid)
      const tasks = tasksGroupedOnTemplate[templateUid]
      const configs = await getConfig(templateUid)
      if (taskTemplate.is_minibid) {
        const introTemplate = await getIntroTemplateByTemplateUid(templateUid)
        return {
          ...taskTemplate,
          configs,
          ...configs.columns,
          tasks,
          deadlineField: introTemplate.deadline,
        }
      }
      return { ...taskTemplate, configs, ...configs.columns, tasks }
    }),
  )
  return taskTemplates
}
