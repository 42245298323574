import { simpleDate } from "@/utils/helpers"
import {
  faArrowsRotate,
  faTrashCan,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RangePickerProps } from "antd/es/date-picker"
import dayjs, { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import { GenericDateRangePicker } from "./GenericDateRangePicker"

type IAgreementLength = Omit<RangePickerProps, "value" | "onChange"> & {
  label?: string
  readOnly?: boolean
  value: Dayjs[][]
  onChange(dates: Dayjs[][]): void
}

export const AgreementLength = ({
  readOnly = false,
  label,
  value = [[]],
  onChange,
  ...props
}: IAgreementLength) => {
  const [dates, setDates] = useState([])

  useEffect(() => {
    if (Array.isArray(value) && value?.every((v) => Array.isArray(v))) {
      setDates(
        value
          .filter((v) => v[0])
          .map(([start, end]) => [dayjs(start), dayjs(end)]),
      )
    }
  }, [])

  const onChangeHandler = (date: Dayjs[] | null, i: number) => {
    setDates((prev) => {
      const res = prev.map((v, index) => {
        if (index === i) return date
        return v
      })
      onChange?.(res)
      return res
    })
  }

  const handleClick = () => {
    const endDateOfLastDate = dates[dates.length - 1]?.[1]
    if (endDateOfLastDate) {
      setDates((dates) => [...dates, [endDateOfLastDate]])
    } else {
      setDates((dates) => [...dates, []])
    }
  }

  const handleDelete = (index) => {
    const newDates = [...dates]
    newDates.splice(index, 1)
    setDates(newDates)
    onChange?.(newDates)
  }

  let placeholder: [string, string] = props?.placeholder ?? [
    "Start date",
    "End date",
  ]

  if (props?.format && typeof props.format === "string") {
    placeholder = [props.format, props.format]
  }

  return (
    <div>
      <div className="flex w-full flex-col">
        {label && (
          <p className="flex w-full flex-col flex-wrap text-xs font-medium text-gray-500">
            {label}
          </p>
        )}
        {dates.map((date, i) => (
          <div className="mt-2 flex w-full items-center first:mt-0" key={i}>
            <div className="relative w-full">
              <div className="flex items-center gap-2">
                {i !== 0 && <FontAwesomeIcon icon={faArrowsRotate} />}
                {readOnly ? (
                  <div>
                    {date
                      ? `${simpleDate(date[0])} - ${simpleDate(date[1])}`
                      : "No dates selected"}
                  </div>
                ) : (
                  <div className="w-full">
                    <GenericDateRangePicker
                      placeholder={placeholder}
                      value={date as RangePickerProps["value"]}
                      defaultPickerValue={
                        [dates[i - 1]?.[1], dayjs()] || [dayjs(), dayjs()]
                      }
                      onChange={(dates: Dayjs[]) =>
                        onChangeHandler(dates ?? [], i)
                      }
                      disabledDate={(d) =>
                        i !== 0 && d.isBefore(dates[i - 1]?.[1])
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            {readOnly === false && i !== 0 ? (
              <div
                className="mt-1 flex cursor-pointer pl-2 hover:text-red-500"
                onClick={() => handleDelete(i)}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div className="mt-2 flex">
        {readOnly === false && dates.every((v) => v?.[1]) ? (
          <button onClick={handleClick}>
            <p className="text-xs">+ Add new date range</p>
          </button>
        ) : null}
      </div>
    </div>
  )
}
