import { INotification } from "@/features/notifications/types"
import {
  getNotifications,
  getUnresolvedNotificationsCount,
} from "@/features/notifications/utils/api"
import { useQuery } from "@tanstack/react-query"
import { queryClient } from "pages/_app"

export const refetchNotifications = () =>
  queryClient.invalidateQueries({ queryKey: ["notifications"] })

export const useNotifications = ({ limit }: { limit?: number } = {}) => {
  const { data: notifications } = useQuery<INotification[]>({
    queryKey: ["notifications", { limit }],
    queryFn: () => getNotifications(limit),
    placeholderData: [],
  })
  return notifications
}

export const useUnresolvedNotificationsCount = () => {
  const { data: count } = useQuery<number>({
    queryKey: ["notifications", "unresolved", "count"],
    queryFn: () => getUnresolvedNotificationsCount(),
    placeholderData: 0,
  })
  return count
}

export const setNotifications = (
  updater: (notifications: INotification[] | undefined) => INotification[],
) => {
  return queryClient.setQueryData<INotification[]>(["notifications"], updater)
}
