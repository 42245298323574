import { BasicButton } from "@/components/buttons/BasicButton"
import { siblingsCollection } from "@/utils/api/collections"
import { cn } from "@/utils/helpers"
import {
  faCheck,
  faChevronDown,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { DropdownItem } from "../items/DropdownItem"
import { DropdownGeneric } from "./DropdownGeneric"

interface IExtendedTask {
  value: { extended_task_uid: string; continuation_type: string }
  task: string
  label?: string
  placeholder: string
  onChange({
    extended_task_uid,
    continuation_type,
  }: {
    extended_task_uid: string
    continuation_type: string
  }): void
}

export enum CONTINUATION {
  INTERNAL,
  EXTERNAL,
}

export const ExtendedTask = ({
  value,
  task,
  label,
  placeholder,
  onChange,
}: IExtendedTask) => {
  const [parents, setParents] = useState<any[]>([])
  const extendedTask = parents.find((p) => p.uid === value?.extended_task_uid)

  useEffect(() => {
    fetchParentTasks()
  }, [task])

  const fetchParentTasks = async () => {
    const tasks = await siblingsCollection(task)
    setParents(tasks)
  }

  const toggleContinuation = (key: CONTINUATION) => () => {
    const continuation_type =
      value?.continuation_type === CONTINUATION[key] ? null : CONTINUATION[key]
    onChange({ ...value, continuation_type })
  }

  const toggleTask = (task: any) => {
    const extended_task_uid =
      value?.extended_task_uid === task.uid ? null : task.uid
    onChange({ ...value, extended_task_uid })
  }

  const getDropdownText = () => {
    if (extendedTask) {
      return extendedTask.name ?? extendedTask.uid
    }
    if (!parents.length) {
      return "No tasks available"
    }
    return placeholder
  }

  return (
    <div
      className={cn(
        "border-custom-gray group relative flex min-h-[3rem] w-full flex-col gap-0.5 rounded-xl border bg-white px-4 py-3 ring-inset group-focus:ring-2",
      )}
    >
      <span
        className={cn(
          "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
        )}
      >
        {label}
      </span>
      <DropdownGeneric
        collection={parents}
        renderItem={({ item }: { item: any }) => (
          <DropdownItem
            title={item?._name ?? item.uid}
            selected={item.uid === extendedTask?.uid}
            icon={faCheck}
            onClick={() => toggleTask(item)}
          />
        )}
        button={
          <div className="flex items-center justify-between gap-4">
            <p
              className={`${!!extendedTask ? "" : "text-custom-gray-dark"} text-sm`}
            >
              {getDropdownText()}
            </p>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        }
      />
      {value?.extended_task_uid && (
        <div className="mt-4 flex flex-col gap-2">
          <BasicButton
            className={`w-full ${
              value?.continuation_type === CONTINUATION[CONTINUATION.INTERNAL]
                ? "!border-none !bg-blue-500 !text-white"
                : ""
            }`}
            onClick={toggleContinuation(CONTINUATION.INTERNAL)}
          >
            Continuation of own consultant
          </BasicButton>
          <BasicButton
            className={`w-full ${
              value?.continuation_type === CONTINUATION[CONTINUATION.EXTERNAL]
                ? "!border-none !bg-blue-500 !text-white"
                : ""
            }`}
            onClick={toggleContinuation(CONTINUATION.EXTERNAL)}
          >
            Continuation competitor
          </BasicButton>
        </div>
      )}
    </div>
  )
}
