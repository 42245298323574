export class TaskTemplateListView {
  uid: string
  name: string
  num: number

  constructor(uid: string, name: string, num: number) {
    this.uid = uid
    this.name = name
    this.num = num
  }

  static fromJSON(json: any): TaskTemplateListView {
    return new TaskTemplateListView(json.uid, json.name, 0)
  }
}
