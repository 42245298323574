import { IExtendedTemplate } from "@/features/templates/types"
import { fetcher } from "@/utils/api"
import { injectable } from "tsyringe"
import { ITask } from "../types/itask"
import { TemplateFactory } from "../factories/templateFactory"
import { TaskTemplateListView } from "../models/taskTemplateListView"

const TASK_TEMPLATE_APP = "52e7c717-d7fe-48fb-8916-61b8fea4ecbb"

@injectable()
export class TaskRepository {
  constructor() {}

  async getTasksAll(state: string): Promise<ITask[]> {
    const url: string = `/tasks${state ? `?state=${state}` : ""}`
    const tasks: ITask[] = await fetcher(url)
    return tasks
  }

  async getExtendedTemplate(templateId: string): Promise<IExtendedTemplate> {
    const url: string = `/apps/${TASK_TEMPLATE_APP}/data/templates/${templateId}`
    const template: IExtendedTemplate = await fetcher(url)
    return template
  }

  async getSidebarTemplates(): Promise<TaskTemplateListView[]> {
    const url: string = `/tasks/sidebar`
    const result: any[] = await fetcher(url)
    const templates: TaskTemplateListView[] =
      TemplateFactory.parseListView(result)
    return templates
  }
}
