import "reflect-metadata"
import { TaskProvider } from "@/modules/tasks/providers/taskProvider"
import { TaskRepository } from "@/modules/tasks/repositories/taskRepository"
import { CacheProvider } from "@/shared/cache/cacheProvider"
import { container, InjectionToken } from "tsyringe"

export const useStartup = () => {
  container.register(TaskRepository, { useClass: TaskRepository })
  container.register(TaskProvider, {
    useValue: new TaskProvider(
      getService(CacheProvider),
      getService(TaskRepository),
    ),
  })
}

export const getService = <T>(token: InjectionToken<T>): T => {
  return container.resolve(token)
}
