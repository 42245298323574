import {
  FieldClassification,
  FieldFilterValues,
  FilterableFieldType,
  IField,
} from "@/features/fields/types"
import {
  faBarsProgress,
  faBuilding,
  faCalendar,
  faCalendarRange,
  faChevronCircleDown,
  faCircleDollar,
  faCirclePlus,
  faClock,
  faEyes,
  faFiles,
  faHashtag,
  faIdCard,
  faList,
  faMoneyBill,
  faMoneyCheckDollar,
  faNoteSticky,
  faPartyHorn,
  faPeople,
  faPercent,
  faPhone,
  faPrint,
  faRotate,
  faSignature,
  faSlider,
  faSlidersSimple,
  faStars,
  faTags,
  faText,
  faToggleOff,
  faUserCrown,
  faUserTie,
  faUsers,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"

export const TRIPLETEX_FIELD_TYPES = {
  TRIPLETEX_PROJECT_NAME: "tripletex_project_name",
  TRIPLETEX_DESCRIPTION: "tripletex_description",
  TRIPLETEX_PROJECT_MANAGER: "tripletex_project_manager",
  TRIPLETEX_DEPARTMENT: "tripletex_department",
  TRIPLETEX_MAIN_PROJECT: "tripletex_main_project",
  TRIPLETEX_START_DATE: "tripletex_start_date",
  TRIPLETEX_END_DATE: "tripletex_end_date",
  TRIPLETEX_CUSTOMER: "tripletex_customer",
  TRIPLETEX_IS_CLOSED: "tripletex_is_closed",
  TRIPLETEX_READY_FOR_INVOICING: "tripletex_is_ready_for_invoicing",
  TRIPLETEX_IS_INTERNAL: "tripletex_is_internal",
  TRIPLETEX_IS_OFFER: "tripletex_is_offer",
  TRIPLETEX_IS_FIXED_RPICE: "tripletex_is_fixed_price",
  TRIPLETEX_PROJECT_CATEGORY: "tripltex_project_category",
  TRIPLETEX_DELIVERY_ADDRESS: "tripletex_delivery_address",
  TRIPLETEX_REFERENCE: "tripletex_reference",
  TRIPLETEX_EXTERNAL_ACCOUNT_NUMBER: "tripletex_external_account_number",
  TRIPLETEX_DISCOUNT_PERCENTAGE: "tripletex_discount_percentage",
  TRIPLETEX_VAT_TYPE: "tripletex_vat_type",
  TRIPLETEX_FIXED_PRICE: "tripletex_fixed_price",
  TRIPLETEX_CONTRIBUTION_MARGIN_PERCENT:
    "tripletex_contribution_margin_percent",
  TRIPLETEX_NUMBER_OF_SUBPROJECTS: "tripletex_number_of_subprojects",
  TRIPLETEX_NUMBER_OF_PROJECT_PARTICIPANTS:
    "tripletex_number_of_project_participants",
  TRIPLETEX_CURRENCY: "tripletex_currency",
  TRIPLETEX_MARKUP_FEES_EARNED: "tripletex_markup_fees_earned",
  TRIPLETEX_IS_PRICE_CEILING: "tripletex_is_price_ceiling",
  TRIPLETEX_PRICE_CEILING_AMOUNT: "tripletex_price_ceiling_amount",
  TRIPLETEX_PROJECT_HOURLY_RATES: "tripletex_project_hourly_rates",
  TRIPLETEX_FOR_PARTICIPANTS_ONLY: "tripletex_for_participants_only",
  TRIPLETEX_PARTICIPANTS: "tripletex_participants",
  TRIPLETEX_CONTACT: "tripletex_contact",
  TRIPLETEX_ATTENTION: "tripletex_attention",
  TRIPLETEX_INVOICE_COMMENT: "tripletex_invoice_comment",
  TRIPLETEX_INVOICE_DUEDATE: "tripletex_invoice_duedate",
  TRIPLETEX_INVOICE_DUEDATE_TYPE: "tripletex_invoice_duedate_type",
  TRIPLETEX_INVOICE_RECEIVER_EMAIL: "tripletex_invoice_receiver_email",
} as const

export const FIELD_TYPES = {
  TEXT: "text",
  TEXTAREA: "textarea",
  NUMBER: "number",
  DATE: "date",
  DATE_RANGE: "date_range",
  MULTI_DATE_RANGE: "multi_date_range",
  MONEY: "money",
  SELECT: "select",
  MULTISELECT: "multiselect",
  PHONE: "phone",
  PRICE_RANGE: "price_range",
  PRICE_SELECTOR: "price_selector",
  AGREEMENT_OWNER: "agreement_owner",
  FTE: "fte",
  EXTENDED_TASK: "extended_task",
  TAGS: "tags",
  SLIDER: "slider",
  TOGGLE: "toggle",
  PARTY: "party",
  CV_TEMPLATES: "cvpartner_templates",
  ...TRIPLETEX_FIELD_TYPES,
} as const

export const FILTERABLE_FIELD_TYPES = {
  TEXT: "text",
  TEXTAREA: "textarea",
  NUMBER: "number",
  SELECT: "select",
  MULTISELECT: "multiselect",
} as const

export const FieldTypes: IField[] = [
  {
    key: "text",
    type: FIELD_TYPES.TEXT,
    label: "Text",
    required: false,
    placeholder: "",
  },
  {
    key: "textarea",
    type: FIELD_TYPES.TEXTAREA,
    label: "Textarea",
    required: false,
  },
  {
    key: "number",
    type: FIELD_TYPES.NUMBER,
    label: "Number",
    required: false,
    placeholder: "",
  },
  {
    key: "date",
    type: FIELD_TYPES.DATE,
    label: "Date",
    required: false,
    placeholder: "",
  },
  {
    key: "date_range",
    type: FIELD_TYPES.DATE_RANGE,
    label: "Date Range",
    required: false,
    placeholder: "",
  },
  {
    key: "multi_date_range",
    type: FIELD_TYPES.MULTI_DATE_RANGE,
    label: "Multi Date Range",
    required: false,
    placeholder: "",
  },
  {
    key: "money",
    type: FIELD_TYPES.MONEY,
    label: "Money",
    required: false,
  },
  {
    key: "select",
    type: FIELD_TYPES.SELECT,
    label: "Select",
    required: false,
    placeholder: "",
  },
  {
    key: "multiselect",
    type: FIELD_TYPES.MULTISELECT,
    label: "Multi-select",
    required: false,
    placeholder: "",
  },
  {
    key: "phone",
    type: FIELD_TYPES.PHONE,
    label: "Phone",
    required: false,
    placeholder: "",
  },
  {
    key: "price_selector",
    type: FIELD_TYPES.PRICE_SELECTOR,
    label: "Price selector",
    required: false,
  },
  {
    key: "price_range",
    type: FIELD_TYPES.NUMBER,
    label: "Price range",
    required: false,
  },
  {
    key: "agreement_owner",
    type: FIELD_TYPES.AGREEMENT_OWNER,
    label: "Agreement owner",
    required: false,
  },
  {
    key: "fte",
    type: FIELD_TYPES.FTE,
    label: "FTE",
    required: false,
  },
  {
    key: "extended_task",
    type: FIELD_TYPES.EXTENDED_TASK,
    label: "Extended task",
    required: false,
  },
  {
    key: "tags",
    type: FIELD_TYPES.TAGS,
    label: "Tags",
    required: false,
  },
  {
    key: "slider",
    type: FIELD_TYPES.SLIDER,
    label: "Slider",
    required: false,
  },
  {
    key: "toggle",
    type: FIELD_TYPES.TOGGLE,
    label: "Toggle",
    required: false,
  },
  {
    label: "Party",
    key: "party",
    type: FIELD_TYPES.PARTY,
    required: false,
  },
  {
    key: "library",
    type: "library" as any, // Needed for the library field
    label: "Import from library",
    required: false,
  },
  {
    key: "candidate_status",
    type: "candidate_status",
    label: "Candidate status",
    required: false,
  },
]

export const FieldTypeIcons = {
  text: faText,
  textarea: faNoteSticky,
  number: faHashtag,
  date: faCalendar,
  date_range: faCalendar,
  multi_date_range: faCalendarRange,
  money: faMoneyBill,
  select: faChevronCircleDown,
  multiselect: faList,
  phone: faPhone,
  attachments: faFiles,
  price_range: faSlidersSimple,
  pricings: faCircleDollar,
  price_selector: faMoneyCheckDollar,
  progress_bar: faBarsProgress,
  suppliers: faUserTie,
  agreement_owner: faUserCrown,
  fte: faClock,
  extended_task: faRotate,
  cv_templates: faPrint,
  tags: faTags,
  responsibles: faUsers,
  owners: faUserCrown,
  contract_suppliers: faUserTie,
  talents: faStars,
  task_talents: faStars,
  viewers: faEyes,
  slider: faSlider,
  toggle: faToggleOff,
  customer: faBuilding,
  signature: faSignature,
  party: faPartyHorn,
  type: faIdCard,
  people_user: faUserTie,
  people_fullname: faText,
  people_company: faBuilding,
  people_allocation: faPercent,
  library: faCirclePlus,
  candidate_status: faPeople,
}

export const FieldTypeData = {
  text: "",
  textarea: "",
  number: 0,
  date: new Date().toISOString(),
  date_range: [new Date().toISOString(), new Date().toISOString()],
  multi_date_range: [[new Date().toISOString(), new Date().toISOString()]],
  money: 0,
  select: [],
  multiselect: [],
  phone: 4512345678,
  agreement_owner: { name: "", uid: "" },
  fte: 37,
  extended_task: { extended_task_uid: "", continuation_type: "" },
  tags: [],
  cv_templates: [],
  slider: 0,
  toggle: false,
  candidate_status: {},
}

export const DEFAULT_FILTER_DATA: Record<
  FilterableFieldType,
  FieldFilterValues[FilterableFieldType]
> = {
  text: {
    type: "contains",
    value: "",
  },
  textarea: {
    type: "contains",
    value: "",
  },
  number: {
    type: "equal",
    value: undefined,
  },
  select: {
    type: "is",
    value: [],
  },
  multiselect: {
    type: "contains",
    value: [],
  },
}

export const TEXT_FILTER_TYPES = {
  is: "Is",
  is_not: "Is not",
  contains: "Contains",
  does_not_contain: "Does not contain",
  starts_with: "Starts with",
  ends_with: "Ends with",
  is_empty: "Is empty",
  is_not_empty: "Is not empty",
} as const

export const TEXT_AREA_FILTER_TYPES = {
  contains: "Contains",
  does_not_contain: "Does not contain",
  is_empty: "Is empty",
  is_not_empty: "Is not empty",
} as const

export const NUMBER_FILTER_TYPES = {
  equal: "=",
  not_equal: "≠",
  greater: ">",
  less: "<",
  greater_or_equal: "≥",
  less_or_equal: "≤",
  is_empty: "Is empty",
  is_not_empty: "Is not empty",
} as const

export const SELECT_FILTER_TYPES = {
  is: "Is",
  is_not: "Is not",
  contains: "Contains",
  does_not_contain: "Does not contain",
  is_empty: "Is empty",
  is_not_empty: "Is not empty",
} as const

export const CLASSIFICATION_TYPES: Record<FieldClassification, string> = {
  _name: "Name",
  _description: "Description",
  _start_date: "Start date",
  _end_date: "End date",
  _start_and_end_date: "Start and end date",
  _extensions: "Extensions",
  _allocation: "Allocation",
  _status: "Status",
} as const

export const FIELD_CLASSIFICATIONS: Record<string, FieldClassification[]> = {
  text: ["_name", "_description"],
  textarea: ["_description"],
  number: ["_allocation"],
  slider: ["_allocation"],
  select: ["_status"],
  date: ["_start_date", "_end_date"],
  date_range: ["_start_and_end_date"],
  multi_date_range: ["_extensions"],
} as const

export const REQUIRED_CLASSIFICATIONS = ["_name"]

export const TRIPLETEX_KEY_MAPPINGS = {
  text: ["tripletex_project_name"],
  textarea: ["tripletex_description"],
  date: ["tripletex_start_date", "tripletex_end_date"],
} as const

export const TRIPLETEX_KEY_NAMES = {
  tripletex_project_name: "Name",
  tripletex_description: "Description",
  tripletex_start_date: "Start date",
  tripletex_end_date: "End date",
} as const
