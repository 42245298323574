import { getThemeByHost } from "@/utils/api/spaces"
import { useLocalStorage } from "hooks/useLocalStorage"
import { isEmpty } from "lodash"
import { createContext, useContext, useEffect, useState } from "react"

export const ThemeContext = createContext(null)

type TColors = {
  primary?: string
  on_primary?: string
  primary_container?: string
  on_primary_container?: string
  secondary?: string
  on_secondary?: string
  secondary_container?: string
  on_secondary_container?: string
  surface?: string
  on_surface?: string
  on_surface_variant?: string
  inverse_surface?: string
  inverse_on_surface?: string
  inverse_primary?: string
}

type TTheme = TColors & {
  primary?: string
  secondary?: string
  black?: string
  white?: string
  blue?: {
    700?: string
    600?: string
    500?: string
    100?: string
  }
  red?: string
  authimg?: string
  authlogo?: string
  login_screen?: {
    color?: string
    title?: string
    statement?: string
  }
  logosymbol?: string
  login_headline?: string
  login_description?: string
  login_color?: string
  intro?: string
  refereshWithHost?: (host: string) => void
}

export const useTheme = () => useContext<TTheme>(ThemeContext)

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useLocalStorage<TTheme>("_theme_", {})
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    fetchTheme()
  }, [])

  const refereshWithHost = async (host: string) => {
    try {
      let theme = await getThemeByHost(host)
      setTheme(theme)
    } catch (error) {}
  }

  const fetchTheme = async () => {
    if (typeof window === "undefined") return
    try {
      let theme = await getThemeByHost()
      setTheme(theme)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  if (loading && isEmpty(theme)) return null

  function hexToRgb(hex: string) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, "")

    // Parse the r, g, b values
    let bigint = parseInt(hex, 16)
    let r = (bigint >> 16) & 255
    let g = (bigint >> 8) & 255
    let b = bigint & 255

    // Return the RGB string
    return `${r} ${g} ${b}`
  }

  // Add a default value for theme?.primary
  const primaryColor = theme?.primary || "#000"

  console.log(theme)

  return (
    <ThemeContext.Provider value={{ ...theme, refereshWithHost }}>
      {/* eslint-disable-next-line */}
      <style jsx global>
        {`
          :root {
            --color-primary: ${hexToRgb(theme?.primary || "")};
            --color-on-primary: ${hexToRgb(theme?.on_primary || "#fff")};
            --color-secondary: ${hexToRgb(theme?.secondary || "")};
            --color-on-secondary: ${hexToRgb(theme?.on_secondary || "#fff")};
            --color-secondary-container: ${hexToRgb(
              theme?.secondary_container || "",
            )};
            --color-on-secondary-container: ${hexToRgb(
              theme?.on_secondary_container || "#fff",
            )};
            --color-surface: ${hexToRgb(theme?.surface || "")};
            --color-on-surface: ${hexToRgb(theme?.on_surface || "#fff")};
            --color-on-surface-variant: ${hexToRgb(
              theme?.on_surface_variant || "#fff",
            )};
            --color-inverse-surface: ${hexToRgb(theme?.inverse_surface || "")};
            --color-inverse-on-surface: ${hexToRgb(
              theme?.inverse_on_surface || "#fff",
            )};
            --color-inverse-primary: ${hexToRgb(theme?.inverse_primary || "")};

            --color-black: ${theme?.black};
            --color-white: ${theme?.white};
            --color-blue-700: ${theme?.blue?.["700"] || ""};
            --color-blue-600: ${theme?.blue?.["600"] || ""};
            --color-blue-500: ${theme?.blue?.["500"] || ""};
            --color-blue-100: ${theme?.blue?.["100"] || ""};
            --color-red-500: ${theme?.red?.["500"] || ""};
            --image-auth-img: ${theme?.authimg};
            --image-auth-logo: ${theme?.authlogo};
            --image-logo-symbol: ${theme?.logosymbol};
            --color-intro: ${theme?.intro};
          }
        `}
      </style>
      {children}
    </ThemeContext.Provider>
  )
}
