import { getTemplatesFromTasks } from "@/features/intro_templates/lib/api"
import { IExtendedTemplate, ITemplate } from "@/features/templates/types"
import { CacheProvider } from "@/shared/cache/cacheProvider"
import { cloneDeep, isString, sortBy, uniqBy } from "lodash"
import { injectable } from "tsyringe"
import { TaskTemplateListView } from "../models/taskTemplateListView"
import { TaskRepository } from "../repositories/taskRepository"
import { ITask } from "../types/itask"

@injectable()
export class TaskProvider {
  private _cacheProvider: CacheProvider
  private _repository: TaskRepository
  private _selectedTemplateId: string = ""

  constructor(cacheProvider: CacheProvider, repository: TaskRepository) {
    this._cacheProvider = cacheProvider
    this._repository = repository
  }

  _getTaskCacheKey(state: string): string {
    return `task-all-${state}`
  }
  _getTemplateCacheKey(templateId: string): string {
    return `task-template-${templateId}`
  }
  _getTemplatesCacheKey(state: string): string {
    return `task-templates-${state}`
  }
  _getTemplatesBySpaceCacheKey(spaceId: string, state: string): string {
    return `task-templates-${spaceId}-${state}`
  }
  _getTemplateListViewBySpaceCacheKey(spaceId: string, state: string): string {
    return `task-template-list-view-${spaceId}-${state}`
  }
  _getByOwnerCacheKey(state: string): string {
    return `tasks-by-owner-${state}`
  }
  _getBySharedCacheKey(state: string): string {
    return `tasks-by-shared-${state}`
  }
  _getMinibidsCacheKey(state: string): string {
    return `task-minibids-${state}`
  }

  setSelectedTemplate(templateId: string) {
    this._selectedTemplateId = templateId
  }

  async getTasksAll(state: string): Promise<ITask[]> {
    const cacheKey: string = this._getTaskCacheKey(state)
    let tasks: ITask[] = await this._cacheProvider.get(cacheKey)
    if (tasks !== undefined) {
      return tasks
    }

    //    console.log(`getTasksAll - State: ${state}`)
    tasks = await this._repository.getTasksAll(state)
    await this._cacheProvider.add(cacheKey, tasks)

    return tasks
  }

  async _getTasksAllFromCache(state: string): Promise<ITask[]> {
    const cacheKey: string = this._getTaskCacheKey(state)
    let tasks: ITask[] = await this._cacheProvider.get(cacheKey)
    if (tasks !== undefined) {
      return tasks
    }
    return []
  }

  async updateTaskState(task: ITask, fromState: string, toState: string) {
    let tasksFrom: ITask[] = await this._getTasksAllFromCache(fromState)
    let tasksTo: ITask[] = await this._getTasksAllFromCache(toState)

    let taskCached: ITask | null = null
    if (tasksFrom.length > 0) {
      const taskFromIdx: number = tasksFrom.findIndex((t) => t.uid == task.uid)
      if (taskFromIdx !== -1) {
        const cacheKeyFrom: string = this._getTaskCacheKey(fromState)
        taskCached = tasksFrom[taskFromIdx]
        console.log("Before update: ", task)
        taskCached._state = toState
        tasksFrom.splice(taskFromIdx, 1)
        await this._cacheProvider.add(cacheKeyFrom, tasksFrom)
      }
    }

    if (tasksTo.length > 0) {
      if (taskCached !== null) {
        tasksTo.push(taskCached)
      } else {
        const taskToIdx: number = tasksTo.findIndex((t) => t.uid == task.uid)
        if (taskToIdx !== -1) {
          taskCached = tasksTo[taskToIdx]
          taskCached._state = toState
        } else {
          taskCached = cloneDeep(task)
          taskCached._state = toState
          tasksTo.push(taskCached)
        }
      }

      const cacheKeyTo: string = this._getTaskCacheKey(toState)
      await this._cacheProvider.add(cacheKeyTo, tasksTo)
    }
  }

  async getTemplates(state: string): Promise<any> {
    const cacheKey: string = this._getTemplatesCacheKey(state)
    let templates = await this._cacheProvider.get(cacheKey)
    if (templates !== undefined) {
      return templates
    }

    const tasks: ITask[] = await this.getTasksAll(state)
    templates = await getTemplatesFromTasks(tasks)
    await this._cacheProvider.add(cacheKey, templates)

    return templates
  }

  async getExtendedTemplate(
    templateId: string,
  ): Promise<IExtendedTemplate | null> {
    if (templateId === "") return null

    const cacheKey: string = this._getTemplateCacheKey(templateId)
    let template: IExtendedTemplate = await this._cacheProvider.get(cacheKey)
    if (template !== undefined) {
      return template
    }

    template = await this._repository.getExtendedTemplate(templateId)
    if (template) {
      await this._cacheProvider.add(cacheKey, template)
    }

    return template
  }

  async getTableTemplate(
    templateId: string,
    spaceId: string,
    state: string,
  ): Promise<any> {
    const templates: any[] = await this.getTemplatesBySpace(spaceId, state)
    const template = templates.find((t) => t.uid === templateId)
    return template
  }

  _existTemplate(templateId: string, templates: any[]): boolean {
    const template = templates.find((t) => t.uid === templateId)
    return template ? true : false
  }

  async getTemplatesBySpace(spaceId, state: string): Promise<any> {
    if (spaceId === undefined) return []

    const cacheKey: string = this._getTemplatesBySpaceCacheKey(spaceId, state)
    let templates: any[] = await this._cacheProvider.get(cacheKey)
    if (templates !== undefined) {
      if (this._existTemplate(this._selectedTemplateId, templates) == false) {
        const extTemplate: IExtendedTemplate = await this.getExtendedTemplate(
          this._selectedTemplateId,
        )
        if (extTemplate) {
          templates.push(extTemplate)
          await this._cacheProvider.add(cacheKey, templates)
        }
      }

      return templates
    }

    const tasks: ITask[] = await this.getTasksAll(state)
    templates = await getTemplatesFromTasks(tasks)
    //    templates = templates?.filter(
    //      (template) =>
    //        template.is_minibid === false && template._owner.uid === spaceId,
    //    )
    //    templates = templates?.filter((template) => template._owner.uid === spaceId)

    await this._cacheProvider.add(cacheKey, templates)

    return templates
  }

  async getTemplateListViewBySpace(
    spaceId,
    state: string,
  ): Promise<TaskTemplateListView[]> {
    if (spaceId === undefined) return []
    const cacheKey: string = this._getTemplateListViewBySpaceCacheKey(
      spaceId,
      state,
    )
    let templates: TaskTemplateListView[] =
      await this._cacheProvider.get(cacheKey)
    if (templates !== undefined) {
      return templates
    }

    templates = await this._repository.getSidebarTemplates()
    await this._cacheProvider.add(cacheKey, templates)

    return templates
    /*
    let let templatesBySpaceBySpace = await this.getTemplatesBySpace(spaceId, state)
    templatesBySpaceBySpace = templatesBySpaceBySpace?.filter(
      (template) =>
        template.is_minibid === false && template._owner.uid === spaceId,
    )

    let items: TaskTemplateListView[] = []
    for (let i = 0; i < templatesBySpace.length; i++) {
      const template = templatesBySpace[i]
    for (let i = 0; i < templatesBySpace.length; i++) {
      const template = templatesBySpace[i]
      let num: number = 0
      if (template.tasks !== undefined) {
        num = template.tasks.filter(
          (task: any) => task._state === "published" && task._shared === false,
        ).length
      }
      const item = new TaskTemplateListView(template.uid, template.name, num)
      items.push(item)
    }

    await this._cacheProvider.add(cacheKey, items)

    return items
*/
  }

  async getByOwner(state: string): Promise<any> {
    const cacheKey: string = this._getByOwnerCacheKey(state)
    let tasksByOwner = await this._cacheProvider.get(cacheKey)
    if (tasksByOwner !== undefined) {
      return tasksByOwner
    }

    const tasks = await this.getTasksAll(state)
    tasksByOwner = this._groupByOwner(
      tasks?.filter((task) => task?.is_minibid === true),
    )
    await this._cacheProvider.add(cacheKey, tasksByOwner)

    return tasksByOwner
  }

  _groupByOwner(data: any) {
    const groupedData = new Map()

    data?.forEach((item: any) => {
      const ownerUid = item?._owner?.uid
      const ownerName = item?._owner?.name
      const ownerIcon = item?._owner?.icon

      if (!!ownerUid && !groupedData.has(ownerUid)) {
        groupedData.set(ownerUid, {
          ownerUid,
          ownerName,
          ownerIcon,
          tasks: [],
        })
      }

      groupedData.get(ownerUid)?.tasks.push({ ...item })
    })
    return Array.from(groupedData.values())
  }

  _groupByTemplate = (data: any[]) => {
    const groupedData = new Map()

    data?.forEach((item) => {
      const templateUid = item.template.uid
      const templateName = item.template.name
      const template = item.template
      const ownerUid = item?._owner?.uid
      const ownerName = item?._owner?.name
      const ownerIcon = item?._owner?.icon

      if (!groupedData.has(templateUid)) {
        groupedData.set(templateUid, {
          ownerUid,
          ownerName,
          ownerIcon,
          templateUid,
          templateName,
          template,
          tasks: [],
        })
      }

      groupedData.get(templateUid).tasks.push({ ...item })
    })
    return Array.from(groupedData.values())
  }

  async getByShared(state: string): Promise<any> {
    const cacheKey: string = this._getBySharedCacheKey(state)
    let tasksByShared = await this._cacheProvider.get(cacheKey)
    if (tasksByShared !== undefined) {
      return tasksByShared
    }

    const tasks = await this.getTasksAll(state)
    const memoByTemplate = this._groupByTemplate(
      tasks?.filter((task) => !task?.is_minibid && task?._shared),
    )

    tasksByShared = this._groupTemplateByOwner(memoByTemplate)
    await this._cacheProvider.add(cacheKey, tasksByShared)

    return tasksByShared
  }

  _groupTemplateByOwner(data: any) {
    const groupedData = new Map()

    data.forEach((item: any) => {
      const ownerUid = item.ownerUid
      const ownerName = item.ownerName
      const ownerIcon = item.ownerIcon

      if (!groupedData.has(ownerUid)) {
        if (item.uid == "5c3afa74-1913-4a98-9132-d3acbb99c226") {
          console.log("no access")
        }
        if (item.uid == "9ca472fc-92a0-4e35-aa64-723367a401c3") {
          console.log("access")
        }

        groupedData.set(ownerUid, {
          ownerUid,
          ownerName,
          ownerIcon,
          templates: [],
        })
      }

      groupedData.get(ownerUid).templates.push({ ...item })
    })

    return Array.from(groupedData.values())
  }

  async getMinibids(state: string): Promise<ITask[]> {
    const cacheKey: string = this._getMinibidsCacheKey(state)
    let minibidTasks: ITask[] = await this._cacheProvider.get(cacheKey)
    if (minibidTasks !== undefined) {
      return minibidTasks
    }

    const tasks = await this.getTasksAll(state)
    minibidTasks = tasks?.filter((task) => task.is_minibid === true)
    await this._cacheProvider.add(cacheKey, minibidTasks)

    return minibidTasks
  }

  _search(searchValue, value = ""): boolean {
    const res: boolean = value
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(searchValue.toLowerCase().replace(/\s+/g, ""))

    return res
  }

  _isTaskOverdue(task: Record<string, any>, template: any): boolean {
    const isInteresting = task?.interested === true || task?._interested
    const hasCandidates = task?.candidates?.length > 0
    const isOverdue = new Date(task[template.deadlineField]) < new Date()
    if (isOverdue && !isInteresting && !hasCandidates) {
      return true
    }
    return false
  }

  async getFiltered(
    templateId: string,
    state: string,
    filterTemplate: any,
    searchValue: string,
    showOverdue: boolean,
  ): Promise<ITask[]> {
    const tasks: ITask[] = await this.getTasksAll(state)
    const templateTasks = tasks.reduce((result, task) => {
      const taskTemplate: string | ITemplate = task.template
      let taskTemplateId: string = isString(taskTemplate)
        ? taskTemplate
        : taskTemplate?.uid
      if (taskTemplateId === undefined) taskTemplateId = ""
      if (templateId === "" || templateId === taskTemplateId) {
        result.push(task)
      }
      return result
    }, [])

    const filteredTasks = templateTasks.reduce((result, task) => {
      ;(searchValue.trim() === "" ||
        this._search(
          searchValue,
          typeof task?._name === "string" ? task._name : "",
        )) &&
        (filterTemplate?.is_minibid
          ? showOverdue
            ? this._isTaskOverdue(task, filterTemplate)
            : !this._isTaskOverdue(task, filterTemplate)
          : true) &&
        result.push(task)

      return result
    }, [])

    return filteredTasks
  }

  async getUniqueTemplates(
    templateId: string,
    state: string,
    filters,
  ): Promise<[ITask[], any]> {
    const templates: any[] = await this.getTemplates(state)
    const uniques = uniqBy(templates, (t) => t?.uid)
    const uniqueTemplates = sortBy(uniques, (t) => t?.name)
    const filterTemplate =
      uniqueTemplates.find((t) => t?.uid === templateId) ?? uniqueTemplates[0]

    return [uniqueTemplates, filterTemplate]
  }
}
