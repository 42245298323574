import { fetcher } from "@/utils/api"

export const getNotifications = async (limit?: number) => {
  return fetcher(`/notifications${limit ? `?limit=${limit}` : ""}`)
}

export const getUnresolvedNotificationsCount = async () => {
  return fetcher(`/notifications/unresolved/count`)
}

export const updateBatchNotifications = async (
  notifications: { uid: string; props: Record<string, any> }[],
) => {
  return await fetcher(`/notifications/batch`, {
    method: "PUT",
    body: JSON.stringify(notifications),
  })
}

export const resolveBatchNotifications = async (
  uids: string[],
): Promise<boolean> => {
  return await fetcher(`/notifications/batch/resolve`, {
    method: "PUT",
    body: JSON.stringify(uids),
  })
}

export const resolveAllNotifications = async (): Promise<boolean> => {
  return await fetcher(`/notifications/batch/resolve/all`, {
    method: "PUT",
  })
}

export const unresolveBatchNotifications = async (
  uids: string[],
): Promise<boolean> => {
  return await fetcher(`/notifications/batch/unresolve`, {
    method: "PUT",
    body: JSON.stringify(uids),
  })
}
