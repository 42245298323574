import { faImage } from "@awesome.me/kit-44b29310a6/icons/duotone/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isEqual } from "lodash"
import Image from "next/image"
import React, { useEffect, useRef, useState } from "react"
import { cn } from "./helpers"

export const ORGANISATION_AVATERS = (uid) =>
  `https://cdn.flextribe.io/organisations/logos/${uid}`

export const DEFAULT_IMAGE_PILL = (text) =>
  !!text ? (
    <div className="text-primary border-primary grid h-full w-full select-none place-content-center truncate rounded-full border-2 bg-red-500 px-3 py-1.5 font-medium">
      {text}
    </div>
  ) : (
    <></>
  )

export const ImageWithFallback = React.memo(
  ({ onError = () => {}, fallback = null, className = "", ...props }: any) => {
    const [image, setImage] = useState(props.src)
    const [error, setError] = useState(false)
    const [timestamp, setTimestamp] = useState<number>()
    const revalidateRef = useRef(props?.revalidate)

    useEffect(() => {
      setImage(props.src)
    }, [props.src])

    useEffect(() => {
      if (!isEqual(props.revalidate, revalidateRef.current)) {
        revalidateRef.current = props.revalidate
        setTimestamp(Date.now())
      }
    }, [props?.revalidate])

    useEffect(() => {
      if (error && !fallback) {
        setImage(null)
      }
    }, [error])

    if (error && !!fallback) return fallback

    return (
      <>
        {image ? (
          <Image
            className={cn(
              "h-10 w-full max-w-[100px] object-contain",
              className,
            )}
            alt={props.alt ?? ""}
            objectFit="contain"
            {...props}
            src={`${image}${timestamp > 0 ? timestamp : ""}`}
            onError={() => {
              onError?.()
              setError(true)
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faImage}
            className={cn(
              "h-10 w-full max-w-[100px] object-contain text-blue-500",
              className,
            )}
          />
        )}
      </>
    )
  },
  (prev, next) => isEqual(prev, next),
)

ImageWithFallback.displayName = "ImageWithFallback"
