import { fetcher } from "@/utils/api"
import MD5 from "crypto-js/md5"
import { getAppData } from "../../../utils/api/apps"
import {
  USERS_APP,
  deleteAppData,
  postAppData,
  putAppData,
} from "./../../../utils/api/apps/index"
import { INote, IReview, IUser } from "./../types/index"

export const getUsers = async () => {
  const getUsers = (await getAppData(USERS_APP)) as IUser[]

  return getUsers.map((user) => ({
    ...user,
    hash: MD5(JSON.stringify(user)),
  })) as IUser[] | []
}

export const getUser = async (uid: string) => {
  return fetcher(`/users/${uid}`)
}

export const getUserTasks = async (user: string) => {
  return getAppData(USERS_APP, `/${user}/tasks`)
}

export const getUserContracts = async (user: string) => {
  return getAppData(USERS_APP, `/${user}/contracts`)
}

export const getUserFiles = async (user: string) => {
  return getAppData(USERS_APP, `/${user}/files`)
}

export const createUser = async (user: any) => {
  return fetcher("/users", {
    method: "POST",
    body: JSON.stringify(user),
  })
}

export const deleteUser = async (uid: string) => {
  return fetcher(`/users/${uid}`, {
    method: "DELETE",
  })
}

// --------------------- NOTES ---------------------

export const getUserNotes = async (user: string) => {
  return getAppData(USERS_APP, `/${user}/notes`)
}

export const createUserNote = async (user: string, note: INote) => {
  return postAppData(USERS_APP, note, `/${user}/notes`)
}

export const updateUserNote = async (user: string, note: INote) => {
  return putAppData(USERS_APP, note, `/${user}/notes/${note.uid}`)
}

export const deleteUserNote = async (user: string, note: string) => {
  return deleteAppData(USERS_APP, {}, `/${user}/notes/${note}`)
}

// --------------------- REVIEWS ---------------------

export const getUserReviews = async (user: string) => {
  return getAppData(USERS_APP, `/${user}/reviews`)
}

export const createUserReview = async (user: string, review: IReview) => {
  return postAppData(USERS_APP, review, `/${user}/reviews`)
}

export const updateUserReview = async (user: string, review: IReview) => {
  return putAppData(USERS_APP, review, `/${user}/reviews/${review.uid}`)
}

export const deleteUserReview = async (user: string, review: string) => {
  return deleteAppData(USERS_APP, {}, `/${user}/reviews/${review}`)
}

// --------------------- CVP ---------------------

export const syncUsers = async (filters: Record<string, string[]>) => {
  return await fetcher(`/cvpartners/users/sync`, {
    method: "POST",
    body: JSON.stringify(filters),
  })
}
