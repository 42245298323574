import { BasicButton } from "@/components/buttons/BasicButton"
import { faBell, faEye } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Badge, Popover } from "antd"
import dayjs from "dayjs"
import {
  refetchNotifications,
  setNotifications,
  useNotifications,
  useUnresolvedNotificationsCount,
} from "hooks/queries/notificationQueries"
import Link from "next/link"
import { useRouter } from "next/router"
import { useState } from "react"
import { toast } from "sonner"
import { resolveAllNotifications } from "../utils/api"

export const PopoverNotifications = () => {
  const notifications = useNotifications({ limit: 40 })
  const [popoverVisible, setPopoverVisible] = useState(false)
  const unresolvedNoticiationsCount = useUnresolvedNotificationsCount()
  const router = useRouter()

  const togglePopoverVisibility = () => {
    setPopoverVisible((prevState) => !prevState)
  }

  const markAllAsResolved = async () => {
    if (!unresolvedNoticiationsCount) return
    toast.promise(resolveAllNotifications(), {
      loading: "Updating notifications...",
      success() {
        setNotifications((prev) =>
          prev?.map((n) => {
            return { ...n, resolved: true }
          }),
        )
        refetchNotifications()
        return "Updating notifications"
      },
      error: "Failed to update notifications",
    })
  }

  const navigateToNotifications = () => {
    setPopoverVisible(false)
    router.push("/notifications")
  }

  const content = (
    <div className="space-y-4 py-4">
      <div className="flex items-center justify-between gap-12 px-4">
        <div className="flex items-center gap-2">
          <p className="text-lg font-medium">Notifications</p>
          {unresolvedNoticiationsCount > 0 && (
            <Badge count={unresolvedNoticiationsCount} size="default" />
          )}
        </div>
        <button className="text-xs text-blue-500" onClick={markAllAsResolved}>
          Mark all as resolved
        </button>
      </div>
      <div className="h-72 overflow-auto pl-4">
        {notifications.map((notification, index) => (
          <div
            className="flex gap-2 border-b py-4 last:border-none"
            key={index}
          >
            {!notification.resolved && (
              <div className="mt-1 h-2 w-2 rounded-full bg-blue-500" />
            )}
            <div className="w-full">
              <p
                className="mb-1 text-xs font-medium"
                dangerouslySetInnerHTML={{ __html: notification.title }}
              />
              <div className="flex items-center justify-between">
                <p className="text-xs capitalize text-gray-400">
                  {dayjs(notification.created).format("MMM DD, YYYY HH:mm")}
                </p>
                <p className="bg-custom-gray rounded-lg px-2 py-1 text-xs">
                  {notification.default_type === "PERSONAL"
                    ? "Personal"
                    : "Organization"}
                </p>
                {notification.link && (
                  <Link
                    href={`/r/${notification.link}`}
                    onClick={() => setPopoverVisible(false)}
                  >
                    <p className="bg-custom-gray rounded-lg px-2 py-1 text-xs">
                      <FontAwesomeIcon icon={faEye} size="sm" />
                    </p>
                  </Link>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="px-4">
        <BasicButton
          className="w-full"
          variant="primary"
          onClick={navigateToNotifications}
        >
          View all notifications
        </BasicButton>
      </div>
    </div>
  )

  return (
    <Popover
      content={content}
      trigger="click"
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      open={popoverVisible}
      onOpenChange={togglePopoverVisibility}
    >
      <div className="relative">
        <FontAwesomeIcon
          className={`cursor-pointer text-gray-400 ${unresolvedNoticiationsCount > 0 ? "animate-wiggle-pulse-blue" : ""}`}
          icon={faBell}
          size="lg"
        />
        {unresolvedNoticiationsCount > 0 && (
          <div className="absolute right-0 top-0 h-2 w-2 rounded-full bg-blue-500" />
        )}
      </div>
    </Popover>
  )
}
