import { IField } from "@/features/fields/types"
import { Editor, IAllProps } from "@tinymce/tinymce-react"
import { useRef } from "react"
import { Editor as EditorType } from "tinymce"

interface ITextEditor extends IAllProps {
  readOnly?: boolean
  placeholder?: string
  fields?: IField[]
}

export const TextEditor = ({
  placeholder = "Start adding content here...",
  fields,
  readOnly = false,
  init,
  ...props
}: ITextEditor) => {
  const editorRef = useRef<EditorType>(null)

  const insertField = (field: IField) => {
    const value = `{{${field.key}}}`
    editorRef.current.insertContent(value)
  }

  return (
    <div className="rounded-[10px] bg-white">
      <Editor
        {...props}
        disabled={readOnly}
        apiKey={process.env.NEXT_PUBLIC_TINY_API_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          menubar: readOnly
            ? false
            : `edit view insert format tools ${fields ? "custom" : ""}`,
          toolbar: readOnly
            ? false
            : "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}",
          placeholder,
          height: 500,
          promotion: false,
          statusbar: false,
          resize: false,
          ...(init ?? {}),
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
            "autoresize",
          ],
          setup: (editor) => {
            if (fields) {
              fields.forEach((field) => {
                editor.ui.registry.addMenuItem(`insertfield_${field.key}`, {
                  text: field.label,
                  onAction: () => insertField(field),
                })
              })
            }
          },
          ...(fields
            ? {
                menu: {
                  custom: {
                    title: "Add field",
                    items: fields.map((f) => `insertfield_${f.key}`).join(" "),
                  },
                },
              }
            : {}),
        }}
      />
    </div>
  )
}
